import React from "react";
import Guidelines from "../components/sections/Guidelines";

const GuidelinesView = () => {
  return (
    <>
      <Guidelines />
    </>
  );
};

export default GuidelinesView;
