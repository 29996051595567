import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const About = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "About Our Fraternity",
    paragraph:
      "The Kappa Sigma Fraternity was founded in 1869 at the University of Virginia. Since then, it has grown to 316 chapters and colonies across North America. It is one of the largest social fraternities in the world, and leads the way for all college fraternities in terms of new initiates, service hours, and philanthropic donations.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  
                </div> */}
                <hr className="r" />
                <h3 className="mt-0 mb-12">Our Outreach</h3>
                <p className="m-0">
                  The Kappa Sigma Endowment Fund awards Scholarship-Leadership
                  Awards each year to qualified undergraduate members who excel
                  in the classroom, on campus, in the community, and within the
                  Fraternity. These brothers represent the best and brightest of
                  Kappa Sigma. Since 1948, the Endowment Fund has awarded nearly
                  $5 million to thousands of deserving brothers.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/about-page-image-01.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <hr className="w" />
                <h3 className="mt-0 mb-12">The Ideal of a Brotherhood</h3>
                <p className="m-0">
                  The creation of lifelong friendships is a primary benefit of
                  Fraternity membership. The Fraternity fulfills the need of
                  students to belong to an extended family and frequently
                  provides the opportunity to enhance those personal
                  relationships through group living situations. As Brothers,
                  our members have an obligation to have a caring concern for
                  the welfare of our members.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/about-page-image-02.jpeg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <hr className="g" />
                <h3 className="mt-0 mb-12">Growth and Development</h3>
                <p className="m-0">
                  We teach young men social skills that will benefit them
                  throughout their lifetime. The multitude of leadership
                  opportunities available through the Fraternity prepares
                  members for success in their chosen careers. Chapters should
                  organize well-rounded programs and strive for excellence and
                  quality in all undertakings.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/about-page-image-04.jpeg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
